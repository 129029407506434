import {http,http_no_aes} from '@/utils/http'

// 机器人相关
// 2.4获取账户机器人参数信息接口（获取已设置的机器人参数）
// export const getRobot = data => http('/Markets/get_robot_param',data,'post')
// 2.5设置机器人参数
export const setRobot = data => http_no_aes('/Markets/set_robot_param',data,'post')
// 2.6获取机器人列表接口
export const getRobotList = data => http('/Markets/get_robot_list',data,'post')
// 2.10删除机器人列表接口
export const delRobot = data => http('/Markets/del_robot_param',data,'post')
// 2.13获取机器人可选序列列表
export const getPriceSequenceList = data => http('/Markets/get_price_sequence_list',data,'post')
// 2.14获取价格序列详情
export const getPriceSequenceDetail = data => http('/Markets/get_price_sequence_detail',data,'post')
// 2.14保存(或更新)序列, 接口通过name配合account判断是否又重复记录.有重复更新, 没重复新建
export const savePriceSequence = data => http('/Markets/save_price_sequence',data,'post')


// 系统相关
// 2.4获取账户日志记录接口
export const getLog = data => http_no_aes('/System/get_log_for_user',data,'post')
// 导出日志
export const sendLog = data => http_no_aes('/Trade/export_log_send',data,'post')


// 旧版闪电机器人相关接口
import axios from 'axios'
function getDT(){
    var dt = '0.'
    for (var i = 1; i <= 15; i++) {
        var random = Math.floor(Math.random() * 10 + 1);
        dt += random
    }
    return dt
}
// 获取资产
// export const getBalance = data => {
//     data['_dt']=getDT()
//     return new Promise((resolve, reject) =>{
//         axios({
//             url:'/OApi/Bt_rich/get_web_balance_i',
//             params:data,
//             method:'get',
//         }).then(res=>{
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err)        
//         })
//     })
// }
//获取当前盘口所有机器人状态
// export const getRobotParam = data => {
//     data['_dt']=getDT()
//     return new Promise((resolve, reject) =>{
//         axios({
//             url:'/OApi/Bt_rich/get_robot_param',
//             params:data,
//             method:'get',
//         }).then(res=>{
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err)        
//         })
//     })
// }
//获取所有交易所的单数据格式
export const getWebListAll = data => {
    data['_dt']=getDT()
    return new Promise((resolve, reject) =>{
        axios({
            url:'/OApi/Help/get_web_list_all',
            params:data,
            method:'get',
        }).then(res=>{
            resolve(res.data);
        }).catch(err =>{
            reject(err)        
        })
    })
}
//计算dex子账号
// export const calBalance = data => {
//     data['_dt']=getDT()
//     return new Promise((resolve, reject) =>{
//         axios({
//             url:'/OApi/Dex/cal_all_balance_storage',
//             params:data,
//             method:'get',
//         }).then(res=>{
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err)        
//         })
//     })
// }