// 机器人相关
const state = {
    //基础机器人
    cexRobotList1: [
        { robotNo: 6, limit: 2, robotType: 'shua' },
        { robotNo: 7, limit: 2, robotType: 'budan_new' },
    ],
    // 进阶机器人
    cexRobotList2: [
        { robotNo: 10, limit: 2, robotType: 'push' },
        { robotNo: 1, limit: 1, robotType: 'buy_sell' },
        { robotNo: 3, limit: 1, robotType: 'banzhuan' },
        { robotNo: 2, limit: 1, robotType: 'iceberg' },

        { robotNo: 8, limit: 2, robotType: 'hupan_buy' },
        { robotNo: 9, limit: 2, robotType: 'hupan_sell' },
        { robotNo: 11, limit: 2, robotType: 'hugevol' },
        { robotNo: 24, limit: 2, robotType: 'defence' },
        { robotNo: 20, limit: 2, robotType: 'open_cex' },

        // { robotNo: 4, limit: 1, robotType: 'wangge' },
        // { robotNo: 5, limit: 1, robotType: 'grid' },
    ],
    //专家机器人
    cexRobotList4: [
        { robotNo: 12, limit: 4, robotType: 'draw' },
        { robotNo: 13, limit: 2, robotType: 'real_genpan' },
        { robotNo: 14, limit: 4, robotType: 'kongpan' },
        { robotNo: 15, limit: 4, robotType: 'real_genpan_quick' },
    ],

    //dex版 21dex搬砖换成3搬砖 
    robotListDex: [
        { robotNo: 16, limit: 4, robotType: 'shua_dex' },
        { robotNo: 26, limit: 4, robotType: 'shua_dex2' },
        { robotNo: 17, limit: 4, robotType: 'real_genpan_dex' },
        { robotNo: 18, limit: 4, robotType: 'buy_sell_dex' },
        { robotNo: 19, limit: 4, robotType: 'frontrun_dex' },
        // { robotNo: 20, limit: 4, robotType: 'snipe_dex' },// 狙击新盘机器人 下架
        { robotNo: 3, limit: 4, robotType: 'banzhuan' },
        { robotNo: 25, limit: 4, robotType: 'iceberg_dex' },
    ],
    //nft版
    robotListNft: [
        { robotNo: 16, limit: 4, robotType: 'shua_nft' },
        { robotNo: 18, limit: 4, robotType: 'buy_sell_nft' },
    ],
}

const mutations = {
    // SET_ROBOT: (state, vipList) => {
    //     state.vipList = vipList
    // },
}
const actions = {
    // setRobot({commit}){
    //     return new Promise((resolve, reject) => {
    //         getVipList({}).then(res=>{
    //             commit('SET_VIP_LIST',res)
    //             resolve(res)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}